.sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    h2 {
        color: rgba(34, 34, 34, 1);
        font-family: "Sulphur Point";
        font-weight: 400;
        font-size: 42px;
    }

    form {
        min-width: 583px;

        .form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            font-family: Raleway;
            font-weight: 400;
            font-size: 24px;
            line-height: 28.18px;

            label {
                margin-bottom: 10px;
            }

            .sign-in-password-input-wrapper {
                display: grid;
                grid-template-columns: 11fr 1fr;
                align-items: center;
                height: 63px;
                color: #8C8C8C;
                background: #F5F5F5;
                border-radius: 5px;

                .eye-icon {
                    cursor: pointer;
                }
            }

            input {
                font-family: Raleway;
                font-weight: 400;
                font-size: 21px;
                line-height: 24.65px;
                height: 63px;
                border-radius: 5px;
                border: none;
                padding: 0 20px;
                background-color: rgba(245, 245, 245, 1);
                outline: none;
                &::placeholder {
                    color: rgba(140, 140, 140, 1)
                }
                
            }
        }

        .sign-in-button-wrapper {
            display: flex;

            .sign-in-submit-button {
                font-family: Sulphur Point;
                font-weight: 400;
                font-size: 28px;
                line-height: 28px;
                text-align: center;
                width: 100%;
                height: 62px;
                border: none;
                border-radius: 30px;
                background-color: rgba(53, 181, 237, 1);
                &:disabled {
                    background: rgba(211, 211, 211, 0.98);
                }
            }
        }

        .sign-in-forgot-password {
            font-family: Raleway, serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 21.13px;
            margin-bottom: 50px;
            display: flex;
            justify-content: end;
            p {
                color: rgba(43, 58, 103, 1);
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
      }

    .sign-in-with-social {
        min-width: 583px;
        .sign-in-divider {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            color: rgba(140, 140, 140, 1);
            text-align: center;
            box-sizing: border-box;
    
            hr {
                flex: 1;
                height: 1px;
                border: 1px solid rgba(140, 140, 140, 1);
            }
    
            p {
                margin: 0 10px;
                white-space: nowrap;
            }
        }
        .sign-in-social-icons {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-bottom: 80px;
    
            svg {
                width: 50px;
                height: 50px;
                cursor: pointer;
            }
        }
    }

}