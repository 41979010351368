.tabs-container {
  min-width: 583px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  .tab-item {
    padding: 10px;
    text-align: center;
    border-bottom: 2.5px solid #D9D9D9;
    color: #A4A4A4;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      border-color: #2B3A67;
      color: #2B3A67;
    }
  }

  .active {
    border-color: #2B3A67;
    color: #2B3A67;
  }

  &:hover .tab-item:not(:hover) {
    border-color: #D9D9D9;
    color: #A4A4A4;
  }
}
