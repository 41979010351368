.reset-password-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #222222;
  margin-top: 80px;
  margin-bottom: 120px;
  gap: 30px;
  box-sizing: border-box;

  .reset-password-header{
    text-align: center;
    .reset-password-title{
      font-family: "Sulphur Point";
      font-size: 42px;
      line-height: 42px;
    }
  }
  .reset-password-input-wrapper, .reset-confirm-input-wrapper{
    font-family: "Raleway";
    display: flex;
    flex-direction: column;
    gap: 10px;
    .reset-password-label, .reset-confirm-password-label{
      font-size: 24px;
      line-height: 28.18px;
    }
    .reset-password-input-wrapper{
      display: grid;
      grid-template-columns: 11fr 1fr;
      align-items: center;
      height: 63px;
      color: #8C8C8C;
      background: #F5F5F5;
      border-radius: 5px;
      width: 583px;
      input{
        padding: 0 20px;
        font-size: 21px;
        line-height: 24.65px;
        background: #F5F5F5;
        height: 63px;
        border: none;
        border-radius: 5px;
        outline: none;
      }
      input::placeholder{
        color: #8C8C8C;
      }

    }
  }
  .reset-password-button-wrapper{
    margin-top: 20px;
    .reset-password-submit-button{
      font-family: Sulphur Point;
      font-weight: 400;
      font-size: 28px;
      line-height: 28px;
      border: none;
      border-radius: 30px;
      background: #35B5ED;
      width: 583px;
      height: 62px;
    }
  }
}