.student-courses-tabs{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Sulphur Point";
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 50px;
  margin-top: 80px;
}
.student-courses-container{
  padding:  0 100px 70px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 100%;
  .student-enrolled-courses-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    gap: 60px;
    align-items: center;
  }
}
.student-courses-pagination{
  align-self: center;
  margin-bottom: 20px;
}
.student-courses-wrap{
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 11fr;
  align-items: flex-start;
  gap: 40px;
  .student-enrolled-courses-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    gap: 40px;
    align-items: center;
  }
}