
.search-container{
  display: grid;
  grid-template-columns: 23px 11fr;
  align-items: center;
  border: 1px solid #4E4E4E;
  border-radius: 30px;
  height: 47px;
  padding: 10px 15px;
  gap: 10px;
  .search-input{
    border: none;
    outline: none;
    font-family: "Raleway";
    font-weight: 400;
    font-size: 21px;
    line-height: 24.65px;
  }
  .search-input::placeholder{
    color: #8C8C8C;
  }
}