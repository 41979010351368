.authentication-container{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Sulphur Point";
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 50px;
  margin-top: 80px;

}