.student-payments-container{
  display: flex;
  flex-direction: column;
  padding: 100px;
  gap: 40px;
  .student-payments-title{
    font-family: "Sulphur Point";
    font-weight: 400;
    font-size: 42px;
    line-height: 42px;
    text-align: center;
  }
  .student-payments-main{
    display: flex;
    flex-direction: column;
    gap: 40px;
    .student-payments-filter-container{
      position: relative;
      .student-payments-filters{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        padding-right: 20px;
        span{
          cursor: pointer;
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 24px;
          line-height: 32.68px;
        }
      }
      .student-payments-status{
        position: absolute;
        right: 1%;
        top: 100%;
        z-index: 10;
        visibility: visible;
        opacity: 1;
        display: block;
        .student-payments-status-wrap{
          padding: 20px 30px;
          background: #FFFFFF;
          border: 0.5px solid #D9D9D9;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          font-family: "Raleway";
          font-weight: 500;
          font-size: 18px;
          line-height: 34px;
          color: #6A6767;
          span{
            cursor: pointer;
          }
          .c-d-f-active{
            color: #222222;
          }
        }
      }
    }
    .student-payments-course-list-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;
      .student-payments-course-list{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 60px;
      }
    }
  }
}