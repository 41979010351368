/*NAVBAR*/
.navbar-container {
    box-shadow: 0 1px 0 #AAA;
    font-family: 'Raleway';
  }
  .login-link{
    text-decoration: none;
  }
  
  .navbar-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .nav-item {
    cursor: pointer;
    font-size: 26px;
  }
  
  .nav-link, .login-text {
    color: #222222;
  }
  
  .nav-link:hover {
    color: #35b5ed;
  }
  
  .nav-link:active {
    color: #223254;
  }
  
  .navbar-logo {
    object-fit: cover;
    height: 84px;
    width: 203px;
  }
  
  .navbar-login {
    font-size: 24px;
    cursor: pointer;
    border: 1px solid #35B5ED;
    border-radius: 24px;
    padding: 6px 35px;
    font-family: 'Sulphur Point', sans-serif;
  }
  .navbar-user{
    .nav-user-link{
      display: flex;
      align-items: center;
      gap: 20px;
      .nav-user-icon{
        background: #2B3A67;
        height: 54px;
        width: 54px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .login-icon {
    display: flex;
  }
  
  .login-link {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .navbar-login:hover {
    background-color: #35b5ed;
  }
  
  .navbar-login:active {
    background-color: #257fa6;
  }
  .nav-user-link{

  }
  
  @media (min-width: 1440px) and (max-width: 1920px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }
  
    .nav-item {
      font-size: 24px;
    }
  
    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }
  }
  
  @media (min-width: 1300px) and (max-width: 1440px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }
  
    .nav-item {
      font-size: 24px;
    }
  
    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1300px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }
  
    .nav-item {
      font-size: 20px;
    }
  
    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }
  }
  
  
  @media (min-width: 768px) and (max-width: 1024px) {
  
    /*NAVBAR*/
    .navbar-logo {
      height: 68px;
      width: 150px;
    }
  
    .nav-item {
      font-size: 20px;
    }
  
    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }
  }
  
  @media (min-width: 320px) and (max-width: 768px) {
    body {
      overflow-x: hidden;
    }
  
    /*NAVBAR*/
    .navbar-logo {
      height: 48px !important;
      width: 130px !important;
    }
  
    .nav-item {
      font-size: 20px;
    }
  
    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }
  }