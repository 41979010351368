.error-message-container {
    color: #fff;
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 20px;
    background: rgba(244, 67, 54, 0.15);
    border: 1px solid rgba(244, 67, 54, 1);
    padding: 20px;

    .error-message-close {
        width: 100%;
        text-align: end;
        svg {
            width: 20px; 
            height: 20px;
            cursor: pointer;
        }
    }

    .error-message-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Roboto;
        font-weight: 400;
        padding-right: 20px;

        .error-message-icon {
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            svg {
                width: 50px; 
                height: 50px;
            }
            margin-right: 20px;
        }

        .error-message-content {
            h3 {
                font-size: 18px;
            }

            p {
                font-size: 13px;
            }
        }
    }
}