.sign-up-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  gap: 40px;
  color: #222222;
  box-sizing: border-box;
}
.sign-up-header-wrapper{
  display: flex;
  .sign-up-header{
    .sign-up-title{
      font-family: "Sulphur Point";
      font-weight: 400;
      font-size: 42px;
    }
  }
}
.sign-up-options{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  .sign-up-option{
    width: 582px;
    background: #2B3A67;
    border-radius: 20px;
    height: 173px;
    display: grid;
    grid-template-columns: 3fr 7fr 1fr;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    &:hover{
      .sign-up-option-subtitle{
        color: #F5F5F5!important;
      }
    }
    .sign-up-option-text{
      justify-self: left;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .sign-up-option-title{
        font-family: "Sulphur Point";
        font-size: 42px;
        line-height: 42px;
        color: #F5F5F5;
      }
      .sign-up-option-subtitle{
        font-family: 'Raleway';
        font-size: 18px;
        line-height: 21.13px;
        color: #D3D3D3;
      }
    }
  }
}
.sign-up-form{
  min-width: 583px;
  font-family: 'Raleway';
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  input{
    font-size: 21px;
    background: #F5F5F5;
    height: 63px;
    padding: 0 20px;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  input::placeholder {
    color: #8C8C8C;
  }
  .sign-up-row-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .sign-up-name-wrapper, .sign-up-surname-wrapper{
      display: flex;
      flex-direction: column;
    }
  }
  .sign-up-row-contact{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sign-up-phone-wrapper, .sign-up-email-wrapper{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .sign-up-password-wrapper, .sign-up-confirm-password-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .sign-up-password-input-wrapper, .sign-up-confirm-password-input-wrapper{
      display: grid;
      grid-template-columns: 11fr 1fr;
      align-items: center;
      height: 63px;
      color: #8C8C8C;
      background: #F5F5F5;
      border-radius: 5px;
    }
  }
  .sign-up-button-wrapper{
    margin-top: 30px;
    display: flex;
    .sign-up-submit-button{
      width: 100%;
      height: 62px;
      background: #35B5ED;
      border: none;
      border-radius: 30px;
    }
  }
}
.hidden {
  display: none;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}