body {
    font-family: 'Roboto', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }

  a {
    text-decoration: none;
    color: #222222;
  }

  .about-us-section {
    margin: 150px 0;
    text-align: center;
  }

  .about-us-title {
    font-size: 60px;
    line-height: 45px;
    font-family: "Sulphur Point", sans-serif;
    font-weight: 400;
    color: #222222;
    margin-bottom: 70px;
  }


  .header-items {
    font-family: "Roboto", sans-serif;
    color: #222222;
    text-decoration: none;
  }


  /*NAVBAR*/
  .navbar-container {
    box-shadow: 0 1px 0 #AAA;
  }

  .navbar-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .nav-item {
    cursor: pointer;
    font-size: 26px;
  }

  .nav-link {
    color: #222222;
  }

  .nav-link:hover {
    color: #35b5ed;
  }

  .nav-link:active {
    color: #223254;
  }

  .navbar-logo {
    object-fit: cover;
    height: 84px;
    width: 203px;
  }

  .navbar-login {
    font-size: 24px;
    cursor: pointer;
    border: 1px solid #35B5ED;
    border-radius: 24px;
    padding: 6px 35px;
    font-family: 'Sulphur Point', sans-serif;
  }

  .login-icon {
    display: flex;
  }

  .login-link {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navbar-login:hover {
    background-color: #35b5ed;
  }

  .navbar-login:active {
    background-color: #257fa6;
  }


  /* Genel kart container */
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Kartlar arasında boşluk */
    justify-content: center; /* Kartları yatayda ortala */
    padding: 20px 0;
    margin-bottom: 100px;
    .card {
      background-color: white;
      border: 1px solid #ddd;
      border-color: #35B5ED;
      border-radius: 24px;
      color: #222222;
      padding: 15px;
      text-align: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
      flex: 1 1 calc(25% - 40px); /* 4 kartlık bir satır için */
      box-sizing: border-box;
    }

    .card:hover {
      background-color: #35B5ED;
      transform: scale(1.1); /* Hoverda büyütme */
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Gölge efekti */
    }

    .card:active {
      background-color: #223254;
      color: white;
    }
  }

  /* Kart stili */

  /* Responsive düzenleme */
  @media (max-width: 1200px) {
    .cards-container {
      .card {
        flex: 1 1 calc(33.33% - 40px); /* 3 kartlık bir satır için */
      }
    }
  }

  @media (max-width: 900px) {
    .cards-container{
      .card {
        flex: 1 1 calc(50% - 40px); /* 2 kartlık bir satır için */
      }
    }
  }

  @media (max-width: 600px) {
    .cards-container{
      .card {
        flex: 1 1 calc(100% - 40px); /* 1 kartlık bir satır için */
      }
    }
  }

  /* Pop-up overlay */
  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Arka plan rengi */
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .popup-card {
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 600px;
    width: 90%;
    font-family: 'Roboto', sans-serif; /* Yazı tipi */
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    border: none;
    background: none;
    cursor: pointer;
    color: #333; /* Kapatma butonu rengi */
  }

  #popup-title {
    font-size: 28px;
    font-weight: bold;
    color: #333; /* Başlık rengi */
    margin-bottom: 20px;
    text-align: center;
  }

  #popup-content {
    font-size: 20px;
    color: #666; /* İçerik rengi */
    text-align: center;
  }

  #popup-content a {
    color: #0066cc; /* Link rengi */
    text-decoration: none;
    margin: 5px 0;
    display: block;
  }

  #popup-content a:hover {
    text-decoration: underline; /* Link hover rengi */
  }


  /*FOOTER*/
  .footer-container {
    overflow-x: hidden;
    background-color: #2B3A67;
    color: #F5F5F5;
    z-index: 10;
  }

  .footer-logo {
    background-color: #F5F5F5;
    max-width: 23rem !important;
    height: 5rem !important;
  }

  .footer-logo img {
    height: 80px;
    width: 190px;
    margin-left: 10rem;
  }

  .footer-link {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #F5F5F5;
    text-decoration: none;
  }

  .footer-link:hover {
    color: #35b5ed;
  }

  .footer-link:active {
    color: #222222;
  }

  #footer-contact-us-title {
    font-size: 28px;
    font-weight: 400;
  }

  #footer-address {
    font-size: 24px;
    font-weight: 300;
  }

  #footer-phone-number {
    font-size: 24px;
    font-weight: 300;
  }

  .social-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 2.5rem;
  }

  .social-icon:hover path {
    fill: #35b5ed;
  }

  .social-icon:active path {
    fill: #222222;
  }

  /*RESPONSIVENESS*/
  @media (min-width: 1920px) {
  }

  @media (min-width: 1300px) and (max-width: 1920px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }

    .nav-item {
      font-size: 24px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    /*FOOTER*/
    .footer-logo img {
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 22px;
      font-weight: 400;
    }

    #footer-contact-us-title {
      font-size: 24px;
    }

    #footer-address {
      font-size: 20px;
    }

    #footer-phone-number {
      font-size: 20px;
    }

    .social-icon {
      width: 44px;
      height: 44px;
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    /*FOOTER*/
    .footer-logo img {
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 20px;
    }

    #footer-contact-us-title {
      font-size: 20px;
    }

    #footer-address {
      font-size: 16px;
    }

    #footer-phone-number {
      font-size: 16px;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 68px;
      width: 150px;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    /*FOOTER*/
    .footer-logo {
      max-width: 15rem !important;
      height: 4rem !important;
      margin-top: 1.5rem;
      margin-right: 12rem;
    }

    .footer-logo img {
      height: 60px;
      width: 140px;
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 22px;
      font-weight: 300;
    }

    #footer-contact-us-title {
      font-size: 20px;
    }

    #footer-address {
      font-size: 16px;
    }

    #footer-phone-number {
      font-size: 16px;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 2.4rem 0 0 2rem;
    }

  }

  @media (min-width: 300px) and (max-width: 767px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 48px !important;
      width: 130px !important;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    /*FOOTER*/
    .footer-logo {
      max-width: 10rem !important;
      height: 4rem !important;
      margin-top: 1.5rem;
      margin-right: 1rem;
    }

    .footer-logo img {
      height: 60px;
      width: 136px;
      margin-left: 0.5rem;
    }

    .footer-link {
      font-size: 13px;
      font-weight: 300;
    }

    #footer-contact-us-title {
      font-size: 12px;
      margin-bottom: 0 !important;
    }

    #footer-address {
      font-size: 8px;
    }

    #footer-phone-number {
      font-size: 8px;
    }

    .social-icon {
      width: 25px;
      height: 25px;
      padding: 0;
      margin: 2.8rem 0 0 0;
    }

  }

  .nav-left {
    justify-content: left;
  }

  .nav-right {
    justify-content: right;
  }

  .nav-mid {
    justify-content: center;
  }

  .login-span {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  @media (max-width: 1400px) {

    .nav-container .nav-link {
      font-size: 16px;
      white-space: nowrap;
    }

    .navbar-button span {
      font-size: 14px;
    }

    .navbar-button-icon {
      width: 16px;
      height: 20px;
    }

    .nav-container img {
      width: 150px;
      height: 66px;
    }
  }

  @media (max-width: 1023px) {

    .nav-container .nav-link {
      font-size: 12px;
      white-space: nowrap;
    }

    .navbar-button {
      width: 95px;
      padding: 5px 10px;
    }

    .navbar-button span {
      font-size: 12px;
    }

    .cards-container {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .nav-container .nav-link {
      font-size: 16px;
      white-space: nowrap;
    }

    .navbar-button {
      width: 120px;
    }

    .login-span {
      font-size: 14px !important;
    }

    .container-fluid .col-1 {
      display: none;
    }

    .navbar-collapse {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 1000;
    }

    .navbar-nav {
      text-align: center;
    }

    .nav-item {
      margin: 10px 0;
    }

    .navbar-button {
      margin: 10px auto;
      display: block;
    }

    .nav-mid {
      justify-content: center;
    }

    .cards-container {
      margin: 0;
    }
  }


  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .navbar-button:hover {
    color: #35B5ED;
  }

  .nav-link:hover {
    color: #35B5ED;
  }

  .nav-link:active {
    color: #223254;
  }

  #loginBtn {
    animation: pulse 2s infinite;
  }

  #loginBtn:hover {
    background-color: #35B5ED;
    color: white;

  }

  #loginBtn:active {
    background-color: #223254;
  }

