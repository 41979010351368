.group-card{
  width: 390px;
  height: 411px;
  border: 0.5px solid #000000;
  border-radius: 20px;
  overflow: hidden;
  .group-title{
    background: #2B3A67;
    color: #F5F5F5;
    font-family: "Raleway", serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28.18px;
    text-align: center;
    padding: 17px;
  }
  .group-details{
    display: flex;
    flex-direction: column;
    padding: 18px 24px 20px 24px;
    gap: 18px;
    .group-info{
      display: grid;
      grid-template-columns: 1fr 5fr 1fr;
      align-items: center;
      .group-duration{
        font-family: "Sulphur Point", Serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
      }
      .group-price{
        font-family: "Sulphur Point";
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: #35B5ED;
      }
    }
    .group-meta{
      display: flex;
      flex-direction: column;
      font-family: "Raleway";
      font-weight: 400;
      font-size: 20px;
      line-height: 43px;
      margin-left: 6px;
    }
    .group-schedules{
      display: flex;
      flex-direction: column;
      font-family: "Raleway";
      font-weight: 400;
      font-size: 18px;
      line-height: 21.13px;
      gap: 14px;
      .group-schedule{
        border: 0.5px solid #2B3A67;
        display: grid;
        grid-template-columns: 4fr 3fr 2fr;
        align-items: center;
        gap: 20px;
        padding: 6px 8px;
        border-radius: 5px;
      }
    }
    .group-enroll-btn{
      background: #35B5ED;
      border-radius: 30px;
      width: 100%;
      height: 47px;
      font-family: "Sulphur Point";
      font-weight: 400;
      font-size: 21px;
      line-height: 21px;
      text-align: center;
      border: none;
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    }
    .group-enroll-btn:hover {
      background: #72cbf2;
      color: white;
    }
  }
}