.course-details-groups{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  gap: 40px;
  .course-details-groups-title{
    font-family: "Sulphur Point";
    font-weight: 400;
    font-size: 42px;
    line-height: 42px;
    text-align: center;
  }
  .course-details-groups-container{
    display: flex;
    align-items: center;
    gap: 40px;
  }
}
.course-details-review{
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .course-details-review-title{
    text-align: center;
    font-family: "Sulphur Point";
    font-weight: 400;
    font-size: 42px;
    line-height: 42px;
  }
  .course-details-review-header{
    position: relative;
    .course-details-review-summary{
      display: grid;
      grid-template-columns: 31px 10fr 1fr;
      gap: 10px;
      align-items: center;
      border-top: 1px solid #AAAAAA;
      border-bottom: 1px solid #AAAAAA;
      padding: 10px 10px 10px 0px;
      .course-details-review-count{
        font-family: "Raleway";
        font-weight: 400;
        font-size: 28px;
        line-height: 32.87px;
      }
      .course-details-review-sort{
        justify-self: flex-end;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .course-details-review-sort-label{
          font-family: "Raleway";
          font-weight: 400;
          font-size: 24px;
          line-height: 28.18px;
        }
      }
    }
    .course-details-review-filters-container{
      position: absolute;
      right: 0;
      top: 90%;
      z-index: 10;
      visibility: visible;  /* Ensure it's visible */
      opacity: 1;           /* Ensure it's fully visible */
      display: block;
      .course-details-review-filters{
        padding: 20px;
        background: #FFFFFF;
        border: 0.5px solid #D9D9D9;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-family: "Raleway";
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
        color: #6A6767;
        .course-details-review-filter{
          cursor: pointer;
        }
        .c-d-f-active{
          color: #222222;
        }
      }
    }
  }
  .course-details-reviews-list{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .course-details-review-pagination{
    display: flex;
    justify-content: center;
  }
}
.hidden{
  display: none;
}