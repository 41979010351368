.review-card {
  display: grid;
  grid-template-columns: 69px 11fr;
  gap: 20px;
  //padding: 16px;
  align-items: center;
  //gap: 10px;
}

.review-avatar {
  width: 69px;
  height: 68px;
  border-radius: 50%;
  background: #2B3A67;
  overflow: hidden;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  .avatar-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.rewiew-content{
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  border-radius: 20px;
  padding: 10px 20px;
  gap: 10px;
  .review-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .review-name{
      font-family: "Raleway";
      font-weight: 500;
      font-size: 24px;
      line-height: 28.18px;
      color: #222222;
    }
    .review-date{
      font-family: "Raleway";
      font-weight: 400;
      font-size: 21px;
      line-height: 34px;
    }
  }
  .review-stars{
    display: flex;
    align-items: center;
    gap: 3px;
  }
  .review-text{
    text-align: left;
  }
}
