.course-description{
  display: flex;
  flex-direction: column;
  .course-header{
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #F5F5F5;
    padding: 60px 80px 60px 80px;
    background: #2B3A67;
    .course-title{
      font-family: "Sulphur Point";
      font-weight: 400;
      font-size: 54px;
      text-align: center;
      color: #F5F5F5;
    }
    .course-content{
      display: flex;
      align-items: center;
      gap: 60px;
      .course-image-wrapper{
        border-radius: 10px;
        overflow: hidden;
        height: 375px;
        width: 525px;
        .course-image{
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
      .course-info{
        display: flex;
        flex-direction: column;
        gap: 40px;
        .course-summary{
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 32px;
          line-height: 43.58px;
        }
        .course-instructor{
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 24px;
          line-height: 32.68px;
        }
        .course-rating{
          display: flex;
          align-items: flex-end;
          gap: 8px;
          font-family: "Raleway";
          .rating-value{
            font-weight: 400;
            font-size: 24px;
            line-height: 28.18px;
          }
          .rating-stars{
            display: flex;
            align-items: center;
            gap: 4px;
          }
          .rating-count{
            font-weight: 400;
            font-size: 21px;
            line-height: 24.65px;
            align-self: flex-end;
          }
        }
        .course-enrollment{
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 24px;
          line-height: 32.68px;
        }
      }
    }
  }
  .course-details{
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    background: #F5F5F5;
    gap: 10px;
    .course-description-header{
      font-family: "Sulphur Point";
      font-weight: 400;
      font-size: 32px;
      line-height: 32px;
    }
    .course-description-text{
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 24px;
      line-height: 43px;
    }
  }
}
.read-more-link {
  color: #2B3A67; // Starbucks green
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
