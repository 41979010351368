body {
    font-family: 'Roboto', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
}

ul {
    list-style-type: none;
    padding-inline-start: 0;
}

a {
    text-decoration: none;
    color: #222222;
}

.courses-container{
    display: flex;
    flex-direction: column;
    padding: 70px 60px 70px 70px;
    .courses-wrap{
        margin-top: 60px;
        display: grid;
        grid-template-columns: 1fr 11fr;
        align-items: flex-start;
        gap: 0px;
    }
}
.courses-wrap-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.courses-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}
.courses-pagination{
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
