.calendar-schedule {
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 2rem;
  gap: 40px;

  .calendar-container,
  .schedule-container {
    padding: 1.5rem;
    //border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  h2 {
    font-family: "Sulphur Point";
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    text-align: center;

  }

  .react-calendar {
    border: none;
    width: 498px;
    height: 441px;
    border-radius: 20px;
    padding: 20px;
    font-family: "Open Sans", sans-serif;
    background: #f8f8f8;
  }

  .react-calendar__tile {
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
    position: relative;
    overflow: hidden;

  }
  .react-calendar__month-view__weekdays {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: clip;
    text-transform: capitalize;
  }
  .react-calendar__month-view__days{
    margin-top: 14px;
    display: grid!important;
    grid-template-columns: repeat(7, 37px) !important;
    justify-content: space-around;
    row-gap: 14px;
  }
  .react-calendar__month-view__weekdays__weekday {
    //min-width: 100px;
    font-size: 14px;
    padding: 5px;
    white-space: normal;
    text-overflow: clip;
    overflow: visible;

  }
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none; /* Removes default underline */
    display: block; /* Makes it behave like a span */
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 21px;
    line-height: 28.6px;
    cursor: text;
  }
  .react-calendar__tile {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    border-radius: 50%;
    max-width: 38px;
    max-height: 38px;
  }

.react-calendar__month-view__days__day{
  gap: 10px;
}
  .react-calendar__month-view__days__day abbr{
    padding: 20px 0px;
  }
  .react-calendar__tile--active {
    background: #1a2a6c !important;
    color: white !important;
    border-radius: 50%;
    overflow: hidden;
  }

  .highlight {
    background: #1a2a6c !important;
    color: white !important;
    //border-radius: 10px;
  }
}

/* Responsive */
@media (max-width: 768px) {
  .calendar-schedule {
    flex-direction: column;
    align-items: center;
  }

  .schedule-container {
    width: 100%;
  }
}
.react-calendar__navigation__label {
  pointer-events: none; // Disables clicking
  cursor: default; // Shows a normal cursor
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 24px;
  line-height: 32.68px;

}

.react-calendar__navigation__prev-button, .react-calendar__navigation__next-button{
  border-radius: 50%;
}

.schedule-container {
  .schedule-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 712px;
    height: 441px;
    padding: 25.6px 30px;
    background: #F5F5F5;
    border-radius: 20px;
    gap: 10px;
    .schedule-header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 24px;
      line-height: 32.68px;
      img{
        cursor: pointer;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;

    th,
    td {
      //padding: 0.75rem;
      //border-bottom: 1px solid #ddd;
      border: 0.6px solid #AAAAAA;
      padding: 8px;
      text-align: center;
    }
    td {
      //padding: 0.75rem;
      border: 0.6px solid #AAAAAA;
      text-align: center;
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 21px;
      line-height: 28.6px;
    }

    /* Remove top border from all top row cells */
    tr:first-child td {
      border-top: none;
    }

    /* Remove bottom border from all bottom row cells */
    tr:last-child td {
      border-bottom: none;
    }

    /* Remove left border from first column */
    td:first-child {
      border-left: none;
      font-weight: 600;
    }

    /* Remove right border from last column */
    td:last-child {
      border-right: none;
    }


    th {
      background: #eaeaea;
    }

    .join-btn {
      padding: 4px 20px;
      border: none;
      border-radius: 24px;
      font-family: "Sulphur Point";
      font-weight: 400;
      font-size: 20px;
      cursor: pointer;
      background: #D3D3D3;
      color: #B0B0B0;

      &.active {
        background: #35B5ED;
        color: #222222;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}