

.hero-section {
    height: 82.7vh;
  }

  .hero-title {
    font-family: 'Sulphur Point', sans-serif;
    font-size: 54px;
    font-weight: 700;
    color: #F5F5F5;
  }

  .hero-description {
    font-family: 'Raleway';
    font-size: 32px;
    font-weight: 300;
    text-align: center;
    color: #F5F5F5;
    margin-bottom: 80px;
    line-height: 1.5;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }


  .get-started-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 89px;
    border-radius: 30px;
    background: #35B5ED;
    font-size: 28px;
    font-family: 'Sulphur Point', sans-serif;
    cursor: pointer;
    border: none;
  }

  .browse-courses-btn {
    padding: 12px 65px;
    border-radius: 30px;
    font-size: 28px;
    border: 1px solid #35B5ED;
    background: transparent;
    color: #F5F5F5;
    font-family: 'Sulphur Point', sans-serif;
    cursor: pointer;
    outline: none;
  }

  .get-started-btn,
  .browse-courses-btn {
    transition: all 0.3s ease;
  }

  .get-started-btn:hover {
    background-color: transparent;
    border: 1px solid #2a99cb;
    color: #FFFFFF;
  }

  .browse-courses-btn:hover {
    background-color: #35B5ED;
    color: #000;
    border-color: #2a99cb;
  }

  .get-started-btn:focus,
  .browse-courses-btn:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(53, 181, 237, 0.5);
  }

  .how-it-works {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .how-it-works-title {
    font-family: Sulphur Point;
    font-size: 42px;
    font-weight: 400;
  }

  .how-it-works-container {
    display: flex;
    justify-content: center;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    gap: 40px;
    border-right: 1px solid #222222;
    font-family: "Raleway";
  }

  .step:last-child {
    border: none;
  }

  .step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2B3A67;
    border-radius: 50%;
  }

  .step-description {
    font-size: 30px;
    color: #222222;
    text-align: center;
    width: 340px;
  }

  .courses-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px 0;
    height: 100vh;
    background: #F5F5F5;
  }

  .courses-title {
    font-family: 'Sulphur Point';
    font-size: 45px;
    font-weight: 400;
    margin: 0 0 30px 0;

  }

  .courses-list {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 20px;
  }

  .course-card {
    display: flex;
    flex-direction: column;
  }

  .course-image {
    display: flex;
  }

  .course-image img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .course-info-wrap {
    display: flex;
    flex-direction: column;
    border: 0.2px solid #222222;
    padding: 10px;
    gap: 10px;
    border-top: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .course-title {
    font-size: 18px;
    color: #222222;
    font-family: "Raleway";
  }

  .course-author {
    font-size: 12px;
    color: #2B3A67;
    font-family: "Raleway";

  }

  .course-rating {
    display: flex;
    align-items: baseline;
    gap: 2px;
  }

  .rating-value {
    font-size: 13px;
    margin-right: 4px;
  }

  .rating-count {
    font-size: 9px;
    align-self: center;
  }

  .course-price {
    font-size: 16px;
  }

  .enroll-button {
    margin: 10px 0px;
    padding: 6px 0px;
    background-color: #35B5ED;
    font-family: "Sulphur Point";
    font-size: 17px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .see-more-button {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    font-family: "Sulphur Point";
    font-size: 24px;
    border-radius: 24px;
    border: 1px solid #35B5ED;
    cursor: pointer;
  }

  @keyframes button-click-animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  /* Button animation class */
  .clicked {
    animation: button-click-animation 0.3s ease-in-out;
  }

  .see-more-icon {
    display: flex;
  }

  .landing-card {
    border-radius: 24px;
    width: 17.7rem;
    transition: box-shadow 0.3s ease-in-out;
  }

  .landing-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  }

  .card-body {
    font-family: "Raleway";
  }

  .card-title {
    font-size: 18px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .author-text {
    font-size: 14px;
    color: #2B3A67;
    font-weight: normal;
    font-family: "Raleway";
  }

  .card-img-top {
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    height: 11rem;
    width: 17.65rem;
  }

  .btn-enroll {
    font-family: "Sulphur Point";
    color: #222222;
    background-color: #35B5ED;
    border-radius: 20px;
  }

  .btn-enroll:hover {
    background: #33d2f6;
    color: white;
  }

  .btn-enroll:active {
    background: #0c94b4 !important;
    color: #e7e5e5 !important;
  }

/*COURSE-CARDS*/
.courses-card{
  .card {
    border-radius: 24px;
    width: 17.7rem;
    transition: box-shadow 0.3s ease-in-out;
  }

  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  }
}

.card-course-thumbnail {
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  height: 11rem;
  width: 17.65rem;
  display: flex;
}

.card-course-name {
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-course-author {
  font-size: 14px;
  color: #2B3A67;
  font-weight: normal;

}

.course-rating {
  display: flex;
  align-items: baseline;
  gap: 2px;
}

.course-rating-value {
  font-size: 13px;
  margin-right: 4px;
}

.course-rating-count {
  font-size: 9px;
  align-self: center;
}

.course-price {
  font-size: 16px;
}

.card-btn-enroll {
  font-family: "Sulphur Point", sans-serif;
  color: #222222;
  background-color: #35B5ED;
  border-radius: 20px;
}

.card-btn-enroll:hover {
  background: #72cbf2;
  color: white;
}

.card-btn-enroll:active {
  background: #257fa6 !important;
  color: #e7e5e5 !important;
}


.reviews-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 50px 140px;
    height: 100vh;
    margin-bottom: 180px;
  }

  .reviews-title {
    font-family: 'Sulphur Point';
    font-size: 45px;
    font-weight: 400;
    margin: 0;
  }

  .reviews-container {
    align-items: center;
    gap: 20px;
  }

  .review-wrap {
    display: grid;
    grid-template-columns: repeat(3, 359px);
    gap: 80px;
  }


  .review-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px;
    border: 0.2px solid #222222;
    background: #F5F5F5;
    border-radius: 20px;
  }

  .review-text {
    font-size: 20px;
    text-align: center;
    font-family: "Raleway";
  }

  .review-author {
    font-size: 23px;
    color: #2B3A67;
    font-family: "Raleway";
  }


  /*FOOTER*/
  .footer-container {
    overflow-x: hidden;
    background-color: #2B3A67;
    color: #F5F5F5;
    z-index: 10;
  }

  .footer-logo {
    background-color: #F5F5F5;
    max-width: 23rem !important;
    height: 5rem !important;
  }

  .footer-logo img {
    height: 80px;
    width: 190px;
    margin-left: 10rem;
  }

  .footer-link {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #F5F5F5;
    text-decoration: none;
  }

  .footer-link:hover {
    color: #35b5ed;
  }

  .footer-link:active {
    color: #222222;
  }

  #footer-contact-us-title {
    font-size: 28px;
    font-weight: 400;
  }

  #footer-address {
    font-size: 24px;
    font-weight: 300;
  }

  #footer-phone-number {
    font-size: 24px;
    font-weight: 300;
  }

  .social-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 2.5rem;
  }

  .social-icon:hover path {
    fill: #35b5ed;
  }

  .social-icon:active path {
    fill: #222222;
  }

  @media (min-width: 1440px) and (max-width: 1920px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }

    .nav-item {
      font-size: 24px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .courses-section {
      height: auto;
    }

    .reviews-section {
      height: auto;
      margin-bottom: 40px;
    }

    /*FOOTER*/
    .footer-logo img {
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 22px;
      font-weight: 400;
    }

    #footer-contact-us-title {
      font-size: 24px;
    }

    #footer-address {
      font-size: 20px;
    }

    #footer-phone-number {
      font-size: 20px;
    }

    .social-icon {
      width: 44px;
      height: 44px;
      margin-bottom: 2rem;
    }

  }

  @media (min-width: 1300px) and (max-width: 1440px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }

    .nav-item {
      font-size: 24px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .courses-section {
      height: auto;
    }

    .courses-list {

    }

    .reviews-section {
      height: auto;
      margin-bottom: 40px;
    }

    /*FOOTER*/
    .footer-logo img {
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 22px;
      font-weight: 400;
    }

    #footer-contact-us-title {
      font-size: 24px;
    }

    #footer-address {
      font-size: 20px;
    }

    #footer-phone-number {
      font-size: 20px;
    }

    .social-icon {
      width: 44px;
      height: 44px;
      margin-bottom: 2rem;
    }

  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 78px;
      width: 160px;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .how-it-works {
      height: 60vh;
    }

    .how-it-works-title {
      font-size: 41px;
    }

    .step {
      padding: 20px !important;
    }

    .step:last-child {
      border: none;
    }

    .step-description {
      font-size: 23px;

      width: 300px;
    }


    .courses-section {
      height: auto;
    }

    .courses-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      gap: 40px !important;
    }

    .reviews-section {
      height: auto;
      margin-bottom: 40px;
    }

    .review-wrap {
      grid-template-columns: repeat(2, 359px);
    }

    /*FOOTER*/
    .footer-logo img {
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 20px;
    }

    #footer-contact-us-title {
      font-size: 20px;
    }

    #footer-address {
      font-size: 16px;
    }

    #footer-phone-number {
      font-size: 16px;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      margin-bottom: 2rem;
    }
  }


  @media (min-width: 768px) and (max-width: 1024px) {

    /*NAVBAR*/
    .navbar-logo {
      height: 68px;
      width: 150px;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .hero-section {
      height: auto;
      padding: 80px;
    }

    .hero-title {
      font-size: 41px;
    }

    .hero-description {
      font-size: 29px;
      margin-bottom: 40px;
    }

    .hero-buttons {
      flex-direction: column !important;
    }

    .get-started-btn {
      margin-bottom: 10px;
    }

    .how-it-works {
      padding-top: 40px;
      padding-bottom: 40px;
      height: auto;
    }

    .how-it-works-title {
      font-size: 36px;
    }

    .how-it-works-container {
      flex-direction: column;
      padding: 20px !important;
    }

    .step {
      flex-direction: column;
      padding: 40px !important;
      gap: 40px;
      border: none;
      border-bottom: 1px solid #222222;
    }


    .step-description {
      font-size: 23px;
      width: 250px;
    }

    .courses-section {

      height: auto;

    }

    .courses-title {
      font-size: 36px;
    }

    .courses-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      flex-wrap: wrap;
      gap: 40px !important;
    }

    .reviews-section {
      height: auto;
      margin-bottom: 40px;
    }

    .reviews-title {
      font-size: 36px;
    }

    .review-wrap {
      grid-template-columns: 359px;
    }

    /*FOOTER*/
    .footer-logo {
      max-width: 15rem !important;
      height: 4rem !important;
      margin-top: 1.5rem;
      margin-right: 12rem;
    }

    .footer-logo img {
      height: 60px;
      width: 140px;
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 22px;
      font-weight: 300;
    }

    #footer-contact-us-title {
      font-size: 20px;
    }

    #footer-address {
      font-size: 16px;
    }

    #footer-phone-number {
      font-size: 16px;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 2.4rem 0 0 2rem;
    }

  }

  @media (min-width: 320px) and (max-width: 768px) {
    body {
      overflow-x: hidden;
    }

    /*NAVBAR*/
    .navbar-logo {
      height: 48px !important;
      width: 130px !important;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .hero-section {
      height: 52.7vh;
    }

    .hero-title {
      font-size: 21px;
    }

    .hero-description {
      font-size: 16px;
      max-width: 280px;
      margin-bottom: 40px;
    }

    .hero-buttons {
      flex-direction: column !important;
    }

    .get-started-btn {
      padding: 4px 28px;
      font-size: 21px;
      margin-bottom: 10px;
    }

    .browse-courses-btn {
      padding: 4px 24px;
      font-size: 21px;
    }

    .how-it-works {
      padding-top: 40px;
      padding-bottom: 40px;
      height: auto;
    }

    .how-it-works-title {
      font-size: 36px;
    }

    .how-it-works-container {
      flex-direction: column;
      padding: 0px !important;
    }

    .step {
      flex-direction: column;
      padding: 20px 40px !important;
      gap: 40px;
      border: none;
      border-bottom: 1px solid #222222;
    }


    .step-description {
      font-size: 23px;
      width: 250px;
    }

    .courses-section {

      height: auto;
    }

    .courses-title {
      font-size: 36px;
    }

    .courses-list {
      display: grid !important;
      grid-template-columns: 1fr;
      flex-wrap: wrap;
      gap: 40px !important;
    }

    .reviews-section {
      height: auto;
      margin-bottom: 40px;
    }

    .reviews-title {
      font-size: 36px;
    }

    .review-content {
      padding: 20px !important;
    }

    .review-wrap {
      grid-template-columns: 300px;
    }
  }




