.filter-container{
  display: flex;
  flex-direction: column;
  width: 277px;
  border-right: 1px solid #AAAAAA;
  padding-right: 40px;
  .filter-header{
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: "Raleway";
    font-weight: 400;
    font-size: 24px;
    line-height: 28.18px;
    padding-bottom: 20px;

  }
  .filter-section{
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-top: 1px solid #AAAAAA;
    padding: 30px 0px;
    .filter-section-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Raleway";
      font-weight: 400;
      font-size: 21px;
      line-height: 24.65px;
      cursor: pointer;
      .filter-arrow-icon{
        padding-right: 10px;
      }
    }
    .filter-options{
      display: flex;
      flex-direction: column;
      gap: 20px;
      .filter-option{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
.filter-option-text{
  display: flex;
  align-items: center;
  gap: 4px;
  .filter-option-name{
    font-family: "Raleway";
    font-weight: 400;
    font-size: 18px;
    line-height: 21.13px;
  }
  .filter-option-count{
    font-family: "Raleway";
    font-weight: 300;
    font-size: 14px;
    line-height: 16.44px;

  }
}
.filter-stars{
  display: flex;
  align-items: center;
  gap: 2px;
  .filter-star{
    width: 18.85px;
    height: 18px;
  }
}
