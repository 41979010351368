.pagination-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pagination-btn {
  width: 36px;
  height: 36px;
  border: 1px solid #2C3E66;
  border-radius: 50%;
  background: transparent;
  color: #2C3E66;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-btn:hover {
  background: #2C3E66;
  color: white;
}

.pagination-btn.active {
  background: #2C3E66;
  color: white;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-dots {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2C3E66;
  font-size: 14px;
}
