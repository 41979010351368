/*COURSE-CARDS*/

.courses-card{
  display: flex;
  padding: 0;
  .card {
    width: 282px;
    transition: box-shadow 0.3s ease-in-out;
    border: none;
  }
  .card-body{
    border-radius: 0px 0px 20px 20px;
    border: 0.2px solid #222222;
    border-top: none;
  }

  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  }
}
.card-course-thumbnail {
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  height: 178px;
  width: 282px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-course-name {
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-course-author {
  font-size: 14px;
  color: #2B3A67;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-rating {
  display: flex;
  align-items: baseline;
  gap: 2px;
}

.course-rating-value {
  font-size: 13px;
  margin-right: 4px;
}

.course-rating-count {
  font-size: 9px;
  align-self: center;
}

.course-price {
  font-size: 16px;
}

.card-btn-enroll {
  font-family: "Sulphur Point", sans-serif;
  color: #222222;
  background-color: #35B5ED;
  border-radius: 20px;
}
.card-btn-view{
  font-family: "Sulphur Point", sans-serif;
  color: #222222;
  border: 1px solid #35B5ED;
  border-radius: 20px;
}
.card-btn-view:hover {
  background: #35B5ED;
  color: white;
}
.card-btn-enroll:hover {
  background: #72cbf2;
  color: white;
}

.card-btn-enroll:active {
  background: #257fa6 !important;
  color: #e7e5e5 !important;
}

.card-btn-inactive{
  pointer-events: none;
  background: #D3D3D3;
  color: #B0B0B0;
  font-family: "Sulphur Point", sans-serif;
  border-radius: 20px;
  cursor: not-allowed;
}
.course-card-buttons{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


@media (min-width: 1024px) and (max-width: 1300px) {

  /*CARDS*/
  .courses-card{
    .card {
      width: 16rem;
    }
  }


  .card-course-thumbnail {
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    height: 11rem;
    width: 15.9rem;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  /*CARDS*/
  .courses-card{
    .card {
      width: 16rem;
    }
  }

  .card-course-thumbnail {
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    height: 11rem;
    width: 15.9rem;
  }
}

@media (min-width: 300px) and (max-width: 767px) {

  /*CARDS*/
  .courses-card{
    .card {
      width: 16rem;
    }
  }


  .card-course-thumbnail {
    height: 11rem;
    width: 15.9rem;
  }

}