body {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

ul {
    list-style-type: none;
    padding-inline-start: 0;
}

a {
    text-decoration: none;
}
/*FOOTER*/
.footer-container {
    overflow-x: hidden;
    background-color: #2B3A67;
    color: #F5F5F5;
    z-index: 10;
}

.footer-logo {
    background-color: #F5F5F5;
    max-width: 23rem !important;
    height: 5rem !important;
}

.footer-logo img {
    height: 80px;
    width: 190px;
    margin-left: 10rem;
}

.footer-link {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 400;
    color: #F5F5F5;
    text-decoration: none;
}

.footer-link:hover {
    color: #35b5ed;
}

.footer-link:active {
    color: #222222;
}

#footer-contact-us-title {
    font-size: 28px;
    font-weight: 400;
}

#footer-address {
    font-size: 24px;
    font-weight: 300;
}

#footer-phone-number {
    font-size: 24px;
    font-weight: 300;
}

.social-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 2.5rem;
}

.social-icon:hover path {
    fill: #35b5ed;
}

.social-icon:active path {
    fill: #222222;
}

@media (min-width: 1440px) and (max-width: 1920px) {

    /*FOOTER*/
    .footer-logo img {
        margin-left: 5rem;
    }

    .footer-link {
        font-size: 22px;
        font-weight: 400;
    }

    #footer-contact-us-title {
        font-size: 24px;
    }

    #footer-address {
        font-size: 20px;
    }

    #footer-phone-number {
        font-size: 20px;
    }

    .social-icon {
        width: 44px;
        height: 44px;
        margin-bottom: 2rem;
    }

}

@media (min-width: 1300px) and (max-width: 1440px) {

    /*FOOTER*/
    .footer-logo img {
        margin-left: 5rem;
    }

    .footer-link {
        font-size: 22px;
        font-weight: 400;
    }

    #footer-contact-us-title {
        font-size: 24px;
    }

    #footer-address {
        font-size: 20px;
    }

    #footer-phone-number {
        font-size: 20px;
    }

    .social-icon {
        width: 44px;
        height: 44px;
        margin-bottom: 2rem;
    }

}

@media (min-width: 1024px) and (max-width: 1300px) {

    .footer-logo img {
        margin-left: 5rem;
    }

    .footer-link {
        font-size: 20px;
    }

    #footer-contact-us-title {
        font-size: 20px;
    }

    #footer-address {
        font-size: 16px;
    }

    #footer-phone-number {
        font-size: 16px;
    }

    .social-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 2rem;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {

    .footer-logo {
        max-width: 15rem !important;
        height: 4rem !important;
        margin-top: 1.5rem;
        margin-right: 12rem;
    }

    .footer-logo img {
        height: 60px;
        width: 140px;
        margin-left: 5rem;
    }

    .footer-link {
        font-size: 22px;
        font-weight: 300;
    }

    #footer-contact-us-title {
        font-size: 20px;
    }

    #footer-address {
        font-size: 16px;
    }

    #footer-phone-number {
        font-size: 16px;
    }

    .social-icon {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 2.4rem 0 0 2rem;
    }

}

@media (min-width: 320px) and (max-width: 768px) {
    .footer-logo {
        max-width: 10rem !important;
        height: 4rem !important;
        margin-top: 1.5rem;
        margin-right: 1rem;
    }

    .footer-logo img {
        height: 60px;
        width: 136px;
        margin-left: 0.5rem;
    }

    .footer-link {
        font-size: 13px;
        font-weight: 300;
    }

    #footer-contact-us-title {
        font-size: 12px;
        margin-bottom: 0 !important;
    }

    #footer-address {
        font-size: 8px;
    }

    #footer-phone-number {
        font-size: 8px;
    }

    .social-icon {
        width: 25px;
        height: 25px;
        padding: 0;
        margin: 2.8rem 0 0 0;
    }
}
