body {
    font-family: 'Roboto', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }

  a {
    text-decoration: none;
    color: #222222;
  }

  /*NAVBAR*/
  .navbar-container {
    box-shadow: 0 1px 0 #AAA;
  }

  .navbar-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .nav-item {
    cursor: pointer;
    font-size: 26px;
  }

  .nav-link {
    color: #222222;
  }

  .nav-link:hover {
    color: #35b5ed;
  }

  .nav-link:active {
    color: #223254;
  }

  .navbar-logo {
    object-fit: cover;
    height: 84px;
    width: 203px;
  }

  .navbar-login {
    font-size: 24px;
    cursor: pointer;
    border: 1px solid #35B5ED;
    border-radius: 24px;
    padding: 6px 35px;
    font-family: 'Sulphur Point', sans-serif;
  }

  .login-icon {
    display: flex;
  }

  .login-link {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navbar-login:hover {
    background-color: #35b5ed;
  }

  .navbar-login:active {
    background-color: #257fa6;
  }


  /*Contact-Us Page*/
  .contact-body .col-6 {
    padding-left: 6px !important;
  }

  .contact-body {
    display: flex;
    justify-content: space-between;
    padding-right: 0;
  }

  .custom-class{
    .card {
      display: flex;
      justify-content: center;
      background-color: #2B3A67;
      color: white;
      border-radius: 20px;
    }
  }

  .custom-class{
    .card .form-control {
      border-radius: 5px;
    }
  }
  .custom-class{
    .card .btn-primary {
      background-color: #00bfff;
      border: none;
    }
  }




  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .contact-item svg {
    margin-right: 15px;
  }

  .contact-item p {
    font-family: 'Roboto', sans-serif;
    font-size: 21px;
    color: #222222;
    line-height: 32px;
  }

  .contact-info {
    margin-top: 30px;
  }

  .contact-info h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    color: #333;
    margin-bottom: 25px;
  }

  .contact-info p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #222222;
    line-height: 1.6;
    margin-bottom: 65px;
  }

 .custom-class{
   .card.p-4 h4 {
     font-family: 'Roboto', sans-serif;
     font-size: 32px;
     color: #F5F5F5;
     font-weight: 400;
     line-height: 37px;
   }
 }
.custom-class{
  .card input::placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #8C8C8C;
  }
}

.custom-class{
  .card input,
  .card textarea {
    background-color: #F5F5F5;
  }
}

.custom-class{
  .card .mb-3 {
    margin-bottom: 100px;
  }
}

  .custom-btn {
    width: 100%;
    height: 62px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #007bff;
    color: #222222;
    border: none;
    font-family: 'Sulphur Point', sans-serif;
    font-size: 28px;
    border-radius: 30px;
    margin-top: 25px;
  }


  .custom-name {
    width: 100%;
    height: 63px;
    margin-top: 20px;
    padding: 12px 20px;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
  }

  .custom-mail {
    width: 100%;
    height: 63px;
    margin-top: 35px;
    padding: 12px 20px;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
  }

  .custom-textarea {
    width: 100%;
    height: 154px;
    margin-top: 35px;
    padding: 16px 20px;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
  }

  .custom-textarea::placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #8C8C8C;
  }


  /*FOOTER*/
  .footer-container {
    overflow-x: hidden;
    background-color: #2B3A67;
    color: #F5F5F5;
    position: relative;
    bottom: 0;
    z-index: 10;
    margin-top: auto;
  }

  .footer-logo {
    background-color: #F5F5F5;
    max-width: 23rem !important;
    height: 5rem !important;
  }

  .footer-logo img {
    height: 80px;
    width: 190px;
    margin-left: 10rem;
  }

  .footer-link {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #F5F5F5;
    text-decoration: none;
  }

  .footer-link:hover {
    color: #35b5ed;
  }

  .footer-link:active {
    color: #222222;
  }

  #footer-contact-us-title {
    font-size: 28px;
    font-weight: 400;
  }

  #footer-address {
    font-size: 24px;
    font-weight: 300;
  }

  #footer-phone-number {
    font-size: 24px;
    font-weight: 300;
  }

  .social-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 2.5rem;
  }

  .social-icon:hover path {
    fill: #35b5ed;
  }

  .social-icon:active path {
    fill: #222222;
  }


  /*MODAL*/
  .modal {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    border-radius: 20px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
    border: none;
  }

  .modal-close {
    display: flex;
    justify-content: end;
  }

  .modal-body {
    text-align: center;
  }

  .modal-body p {
    margin-top: 10px;
  }

  .modal-close svg:hover {
    cursor: pointer;
  }

  .form-control.is-invalid {
    border: 2px solid #dc3545;
    background-image: none;
  }

  .invalid-feedback {
    display: block;
    color: #dc3545;
    font-size: 18px;
    margin-top: 5px;
  }

  /* Modal styles */
  .modal-content {
    border: none;
  }

  .btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .modal-body h2 {
    color: #2B3A67;
    font-size: 24px;
    font-weight: 500;
  }

  .modal-body p {
    color: #666;
    font-size: 16px;
  }

  .modal-content {
    border-radius: 20px;
    border: none;
  }

  .modal-header {
    border: none;
  }

  .modal-body {
    padding: 2rem;
  }

  .modal-title {
    color: #2B3A67;
    font-size: 24px;
    font-weight: 500;
  }

  .custom-btn:disabled {
    background-color: #cccccc !important;
    cursor: not-allowed;
    box-shadow: none;
    color: #666666 !important;
  }

  .custom-btn:disabled:hover {
    background-color: #cccccc !important;
    color: #666666 !important;
  }

  /*RESPONSIVENESS*/
  @media (min-width: 1920px) {
  }

  @media (min-width: 1300px) and (max-width: 1920px) {

    /* Navbar and Footer Part */
    .navbar-logo {
      height: 78px;
      width: 160px;
    }

    .nav-item {
      font-size: 24px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .contact-body h4 {
      font-size: 22px;
    }

    .contact-body p {
      font-size: 18px;
    }

    .contact-body li {
      font-size: 16px;
    }

    .footer-logo img {
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 22px;
      font-weight: 400;
    }

    #footer-contact-us-title {
      font-size: 24px;
    }

    #footer-address {
      font-size: 20px;
    }

    #footer-phone-number {
      font-size: 20px;
    }

    .social-icon {
      width: 44px;
      height: 44px;
      margin-bottom: 2rem;
    }

    /* Card Part */
    .custom-class{
      .card h4 {
        font-size: 22px !important;
      }
    }


    #contactForm input,
    #contactForm input::placeholder,
    #contactForm textarea,
    #contactForm textarea::placeholder {
      font-size: 16px;
    }

    #contactForm button {
      font-size: 20px;
    }

    .custom-name {
      margin-top: 10px;
    }

    .custom-mail,
    .custom-textarea,
    .custom-btn {
      margin-top: 25px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    /* Navbar and Footer Part */
    .navbar-logo {
      height: 78px;
      width: 160px;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .navbar-button {
      width: 95px;
      padding: 5px 10px;
    }

    .footer-logo img {
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 20px;
    }

    #footer-contact-us-title {
      font-size: 20px;
    }

    #footer-address {
      font-size: 16px;
    }

    #footer-phone-number {
      font-size: 16px;
    }

    /* Body Part */
    .contact-info {
      text-align: center;
    }

    .invalid-feedback {
      font-size: 12px !important;
    }

    .contact-body .list-unstyled li {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      margin-bottom: 2rem;
    }

    .contact-body {
      display: flex;
      flex-direction: column;
    }

    .contact-body .col-6 {
      width: 100%;
      padding: 0 !important;
      margin-bottom: 40px;
    }

    /* Card Size */
    .custom-class{
      .card h4 {
        font-size: 18px !important;
      }
    }


    #contactForm input,
    #contactForm input::placeholder,
    #contactForm textarea,
    #contactForm textarea::placeholder {
      font-size: 12px;
    }

    #contactForm button {
      font-size: 18px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 68px;
      width: 150px;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    .container-fluid .col-1 {
      display: none;
    }

    .contact-body {
      width: 100%;
      padding-right: 12px;
    }

    /*FOOTER*/
    .footer-logo {
      max-width: 15rem !important;
      height: 4rem !important;
      margin-top: 1.5rem;
      margin-right: 12rem;
    }

    .footer-logo img {
      height: 60px;
      width: 140px;
      margin-left: 5rem;
    }

    .footer-link {
      font-size: 22px;
      font-weight: 300;
    }

    #footer-contact-us-title {
      font-size: 20px;
    }

    #footer-address {
      font-size: 16px;
    }

    #footer-phone-number {
      font-size: 16px;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 2.4rem 0 0 2rem;
    }

    .contact-info {
      text-align: center;
    }

    .invalid-feedback {
      font-size: 12px !important;
    }

    .contact-body .list-unstyled li {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      margin-bottom: 2rem;
    }

    .contact-body {
      display: flex;
      flex-direction: column;
    }

    .contact-body .col-6 {
      width: 100%;
      padding: 0 !important;
      margin-bottom: 40px;
    }

    /* Card Size */
    .custom-class{
      .contact-us-card h4 {
        font-size: 18px !important;
      }
    }


    #contactForm input,
    #contactForm input::placeholder,
    #contactForm textarea,
    #contactForm textarea::placeholder {
      font-size: 12px;
    }

    #contactForm button {
      font-size: 18px;
    }
  }

  @media (min-width: 300px) and (max-width: 767px) {
    /*NAVBAR*/
    .navbar-logo {
      height: 48px !important;
      width: 130px !important;
    }

    .nav-item {
      font-size: 20px;
    }

    .navbar-login {
      font-size: 20px;
      padding: 4px 27px;
    }

    /*FOOTER*/
    .footer-logo {
      max-width: 10rem !important;
      height: 4rem !important;
      margin-top: 1.5rem;
      margin-right: 1rem;
    }

    .footer-logo img {
      height: 60px;
      width: 136px;
      margin-left: 0.5rem;
    }

    .footer-link {
      font-size: 13px;
      font-weight: 300;
    }

    #footer-contact-us-title {
      font-size: 12px;
      margin-bottom: 0 !important;
    }

    #footer-address {
      font-size: 8px;
    }

    #footer-phone-number {
      font-size: 8px;
    }

    .social-icon {
      width: 25px;
      height: 25px;
      padding: 0;
      margin: 2.8rem 0 0 0;
    }

    .contact-info {
      text-align: center;
    }

    .invalid-feedback {
      font-size: 12px !important;
    }

    .contact-body .list-unstyled li {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .social-icon {
      width: 40px;
      height: 40px;
      margin-bottom: 2rem;
    }

    .contact-body {
      display: flex;
      flex-direction: column;
    }

    .contact-body .col-6 {
      width: 100%;
      padding: 0 !important;
      margin-bottom: 40px;
    }

    /* Card Size */
    .custom-class{
      .card h4 {
        font-size: 18px !important;
      }
    }


    #contactForm input,
    #contactForm input::placeholder,
    #contactForm textarea,
    #contactForm textarea::placeholder {
      font-size: 12px;
    }

    #contactForm button {
      font-size: 18px;
    }

    .container-fluid .col-1 {
      display: none;
    }

    .contact-body {
      width: 100%;
      padding-right: 12px;
    }
  }

  @media (max-width: 576px) {
    .modal-body h2 {
      font-size: 20px;
    }

    .modal-body p {
      font-size: 14px;
    }
  }