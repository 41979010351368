.forgot-password-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #222222;
  margin-top: 80px;
  margin-bottom: 120px;
  gap: 30px;

  .forgot-password-header{
    text-align: center;
    .forgot-password-title{
      font-family: "Sulphur Point";
      font-size: 42px;
      line-height: 42px;
    }
    .forgot-password-subtitle{
      width: 326px;
      font-family: "Raleway";
      font-size: 21px;
      line-height: 24.65px;
      text-align: center;
      color: #8C8C8C;
    }
  }
  .forgot-password-input-wrapper{
    font-family: "Raleway";
    display: flex;
    flex-direction: column;
    gap: 10px;
    .forgot-password-label{
      font-size: 24px;
      line-height: 28.18px;
    }
    .forgot-password-email-input{
      padding: 0 20px;
      font-size: 21px;
      line-height: 24.65px;
      background: #F5F5F5;
      width: 583px;
      height: 63px;
      border: none;
      border-radius: 5px;
      outline: none;
    }
    .forgot-password-email-input::placeholder{
      color: #8C8C8C;
    }
  }
  .forgot-password-button-wrapper{
    margin-top: 20px;
    .forgot-password-submit-button{
      font-family: Sulphur Point;
      font-weight: 400;
      font-size: 28px;
      line-height: 28px;
      border: none;
      border-radius: 30px;
      background: #35B5ED;
      width: 583px;
      height: 62px;
    }
  }
}