.exam-table-container {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 50px 0 80px;
  h2{
    font-family: "Sulphur Point";
    font-weight: 400;
    font-size: 42px;
    line-height: 42px;
    color: #222222;
    margin: 0;
  }
  table {
    width: 80%;
    margin: auto;
    border-collapse: collapse;
    //border-radius: 8px;
    overflow: hidden;
  }

  th {
    background-color: #2B3A67;
    color: #F5F5F5;
    padding: 20px 35px;
    cursor: pointer;
    font-family: "Sulphur Point";
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    border: 0.6px solid #F5F5F5;
  }
  /* Remove top border from all top row cells */
  tr:first-child th {
    border-top: none;
  }

  /* Remove bottom border from all bottom row cells */
  tr:last-child th {
    border-bottom: none;
  }

  /* Remove left border from first column */
  th:first-child {
    border-left: none;
    font-weight: 600;
  }

  /* Remove right border from last column */
  th:last-child {
    border-right: none;
  }
  td {
    padding: 20px;
    border: 0.6px solid #AAAAAA;
    font-weight: 400;
    font-size: 24px;
    line-height: 32.68px;

  }

  .alt-row {
    background-color: #D5D8E1;
  }

  .course-active {
    color: #222222;
  }

  .course-inactive {
    opacity: 0.8;
    color: #8C8C8C;
  }
}
